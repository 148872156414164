<template>
	<div class="garden-swiper">
		<div class="add-swiper-btn">
			<a-button type="primary" @click="showAddSwiper">
				<a-icon type="plus-circle" />
				<span>添加</span>
			</a-button>
		</div>
		<div class="ant-table-wrapper">
			<div class="ant-spin-nested-loading">
				<div class="ant-spin-container">
					<div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
						<div class="ant-table-content">
							<div class="ant-table-body">
								<table class="">
									<colgroup>
										<col style="width: 68px; min-width: 68px;" />
										<col style="width: 168px; min-width: 168px;" />
										<col />
										<col />
										<col style="width: 185px; min-width: 185px;" />
									</colgroup>
									<thead class="ant-table-thead">
										<tr>
											<th key="sort" class="ant-table-row-cell-break-word">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">排序</span>
													</div>
												</span>
											</th>
											<th key="resource_id" class="ant-table-row-cell-break-word">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">预览图</span>
													</div>
												</span>
											</th>
											<th key="title" class="">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">配置内容</span>
													</div>
												</span>
											</th>
											<th key="3" class="">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">状态</span>
													</div>
												</span>
											</th>
											<th key="operation" class="ant-table-row-cell-break-word ant-table-row-cell-last">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">操作</span>
													</div>
												</span>
											</th>
										</tr>
									</thead>
									<tbody @end="onEnd" v-model="list" :animation="300" is='vuedraggable' 
									class="ant-table-tbody" 
									style="width:100%;display: table-row-group;
    vertical-align: middle;
    border-color: inherit;">
										<tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,index) in list" :key="item.banner_id">
											<td class="ant-table-row-cell-break-word">{{index+1}}</td>
											<td class="ant-table-row-cell-break-word">
												<div class="head-img-box">
													<img :src="$imglink+item.resource_id+'&res_type=mfit_l'" >
												</div>
											</td>
											<td class="">{{item.title}}</td>
											<td class="">{{item.is_show == 1 ? '已上架' : '已下架'}}</td>
											<td class="ant-table-row-cell-break-word">
												<div class="control-box">
													<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
													<a-button size="small" type="danger" @click="toggleShow(item.banner_id,item.is_show)">{{item.is_show == 1?'下架':'上架'}}</a-button>
													<a-popconfirm title="确认删除该模块" ok-text="是" cancel-text="否" @confirm="deleteSwiper(item.banner_id)">
														<a-button :disabled="item.count > 0" size="small" type="primary" :loading="deling"><a-icon style="font-size: 20px;" type="delete" /></a-button>
													</a-popconfirm>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a-modal
			class="home-modal"
			v-model="swiperModal"
			:title="editSwiper.id ? '修改模块' : '添加模块'"
			okText="提交"
			cancelText="取消"
			@ok="confirmSwiper"
			:confirmLoading="editIng"
			@cancel="cancelSwiper"
		>
			<div class="img-box">
				<a-upload class="up-swiper" :action="$domain + '/api/resource/uploadImg'" list-type="picture-card" :showUploadList="false" @change="handleChange">
					<div class="img-box" v-show="form.resource_id">
						<div class="img-mask">
							<div class="icon" @click.stop="deleteImg"><a-icon type="delete" /></div>
						</div>
						<img :src="$imglink + form.resource_id+'&res_type=mfit_l'" />
					</div>
					<div v-show="form.resource_id == ''">
						<a-icon type="plus" v-if="!uploading" />
						<a-icon type="loading" v-else />
						<div class="ant-upload-text">上传图片{{ uploading ? '...' : '' }}</div>
					</div>
				</a-upload>
			</div>
			<div class="title"><a-input v-model="form.title" placeholder="请输入名称" /></div>
			<!-- <div class="sort-box" v-if="editSwiper.id">
				<div class="sub-tl">排序</div>
				<a-input-number class="num-box" id="inputNumber" v-model="form.sort" :min="0" />
			</div> -->
			<div class="link-box">
				<div class="sub-tl">链接</div>
				<a-cascader
					style="width: 100%;"
					v-if="loaded"
					v-model="linkIdArr"
					:options="linkData"
					:load-data="getLink"
					placeholder="选择连接"
					:changeOnSelect="true"
					@change="linkChange"
				/>
			</div>
		</a-modal>
	</div>
</template>
<script>
import vuedraggable from 'vuedraggable'
export default {
	components:{vuedraggable},
	data() {
		return {
			editIng: false,
			dataLoading: false,
			swiperModal: false,
			editSwiper: {
				id: '',
				src: '',
				link: ''
			},
			fileList: [],
			loaded: false,
			linkData: [],
			linkIdArr: [],
			options: [
				{
					value: 'zhejiang',
					label: 'Zhejiang',
					isLeaf: false
				},
				{
					value: 'jiangsu',
					label: 'Jiangsu',
					isLeaf: false
				}
			],
			list: [],
			page: {
				current: 1,
				total: 0
			},
			uploading: false,
			deling: false,
			form: {
				resource_id: '',
				banner_class: 5,
				page_key: 3,
				title: '',
				page_name: '',
				class_id: '',
				class_name: '',
				param_id: '',
				param_name: '',
				is_show: 1,
				sort: 1
			}
		};
	},
	computed: {},
	async created() {
		let link1 = await this.$post('pageList', {
			type: 2,
			page_key: 3
		});
		this.linkData = link1.data.map(item => {
			let a = {
				value: item.code + ',,' + item.name,
				isLeaf: false,
				label: item.name,
				level: item.level
			};
			return a;
		});
		this.loaded = true;
		this.getSwiper();
	},
	mounted() {},
	methods: {
		onEnd() {
			let arr = this.list.map(item => {
				return item.banner_id
			})
			this.$post('bannerOrder',{orderList: arr})
		},
		getSwiper() {
			let t = this;
			t.dataLoading = true;
			t.$post('bannerList', { banner_class: 5, limit: 9999 })
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.list = data.items;
						t.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
					t.dataLoading = false;
				})
				.catch(err => {
					t.dataLoading = false;
				});
		},
		onChange(value) {
			console.log(value);
		},
		showAddSwiper() {
			this.swiperModal = true;
		},
		handleChange(res) {
			if (res.file.status == 'uploading') {
				this.uploading = true;
			}
			if (res.file.status == 'done') {
				this.uploading = false;
				this.form.resource_id = res.file.response.data.resource_id;
			}
		},
		getLink(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions[selectedOptions.length - 1];
			console.log('xuanzede', targetOption);
			let gdata = {};
			if (targetOption.level == 1) {
				gdata = {
					type: 2,
					page_key: 3
				};
			} else if (targetOption.level == 2) {
				gdata = {
					type: 3,
					page_key: 3,
					class_id: targetOption.value.split(',,')[0]
				};
			}

			t.$post('pageList', gdata)
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						console.log(data);
						let sub = [];
						for (let i = 0; i < data.length; i++) {
							sub.push({
								value: data[i].code + ',,' + data[i].name,
								isLeaf:  data[i].level > 2 || (data[i].level == 2 && gdata.page_key == 4),
								label: data[i].name,
								level: data[i].level
							});
						}
						targetOption.children = sub;
						this.linkData = [...this.linkData];
						targetOption.loading = false;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		toggleShow(id, is_show) {
			let t = this;
			if (t.showIng) {
				return;
			} else {
				t.showIng = true;
			}
			t.$post('bannerFabu', { banner_id: id })
				.then(res => {
					t.showIng = false;
					let { code, data, msg } = res;
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用';
						t.$message.success(str, 1.5);
				  t.getSwiper();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.showIng = false;
				});
		},
		linkChange(e) {
			// this.swiper.page_id = e[0];
			// this.swiper.param = e[1];
			this.linkIdArr = e;
			console.log('link::', e);
			for (let i = 0; i < 2; i++) {
				if (e[i]) {
					console.log('213123');
					if (i == 0) {
						this.form.class_id = e[i].split(',,')[0];
						this.form.class_name = e[i].split(',,')[1];
					} else {
						this.form.param_id = e[i].split(',,')[0];
						this.form.param_name = e[i].split(',,')[1];
					}
				} else {
					console.log('zhi');
					if(i == 0) {
						this.form.class_id = ''
						this.form.class_name = ''
					} else {
						this.form.param_id = ''
						this.form.param_name = ''
					}
				}
			}
			console.log(this.form.page_key, this.form.page_name, this.form.class_id, this.form.class_name, this.form.param_id, this.form.param_name);
		},
		toAddSwiper() {
			this.$router.push({ path: '/gardenDetail' });
		},
		deleteImg() {
			this.form.resource_id = '';
		},
		async toEdit(item) {
			let t = this;
			this.swiperModal = true;
			this.editSwiper.id = item.banner_id;
			this.form.resource_id = item.resource_id;
			this.form.page_key = 3;
			this.form.title = item.title;
			this.form.page_name = item.page_name;
			this.form.class_id = item.class_id;
			this.form.class_name = item.class_name;
			this.form.param_id = item.param_id;
			this.form.param_name = item.param_name;
			this.linkIdArr = [];
			if (item.class_id) {
				this.linkIdArr.push(item.class_id + ',,' + item.class_name);
				if (item.param_id) {
					this.linkIdArr.push(item.param_id + ',,' + item.param_name);
				}
			}
			console.log('xiugai', this.linkIdArr);
			if (this.linkIdArr.length > 1) {
				for (let i = 0; i < this.linkData.length; i++) {
					let one_a = 0;
					if (this.linkData[i].value.split(',,')[0] == this.form.class_id) {
						one_a = i;
						console.log('one', this.linkData[i]);
						const info = await this.getAllLink(t.linkData[i]);
						console.log(info);
						let { code, data, msg } = info;
						if (code == 0) {
							console.log(data);
							let sub = [];
							for (let i = 0; i < data.length; i++) {
								sub.push({
									value: data[i].code + ',,' + data[i].name,
									isLeaf: data[i].level > 2,
									label: data[i].name,
									level: data[i].level
								});
							}
							this.linkData[i].children = sub;
							this.linkData = [...this.linkData];
							this.linkData[i].loading = false;
						} else {
							this.$message.error(msg, 1.5);
						}

						if (this.linkIdArr.length == 3) {
							console.log('jinlai???', this.linkData[one_a].children.length);
							let two_a = 0;
							for (let k = 0; k < this.linkData[one_a].children.length; k++) {
								if (this.linkData[one_a].children[k].value.split(',,')[0] == this.form.class_id) {
									two_a = k;
									console.log('two', this.linkData[one_a].children[k]);
									const info = await this.getAllLink(this.linkData[one_a].children[k]);
									console.log(info);
									let { code, data, msg } = info;
									if (code == 0) {
										console.log(data);
										let sub = [];
										for (let i = 0; i < data.length; i++) {
											sub.push({
												value: data[i].code + ',,' + data[i].name,
												isLeaf: data[i].level > 2,
												label: data[i].name,
												level: data[i].level
											});
										}
										this.linkData[one_a].children[k].children = sub;
										this.linkData = [...this.linkData];
										this.linkData[one_a].children[k].loading = false;
										console.log(this.linkData);
									} else {
										this.$message.error(msg, 1.5);
									}
								}
							}
						}
					}
				}
			}
		},
		getAllLink(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions;
			console.log(targetOption);
			let gdata = {};
			if (targetOption.level == 1) {
				gdata = {
					type: 2,
					page_key: 3
				};
			} else if (targetOption.level == 2) {
				gdata = {
					type: 3,
					page_key: 3,
					class_id: targetOption.value.split(',,')[0]
				};
			}
			return t.$post('pageList', gdata);
		},
		confirmSwiper() {
			let t = this;
			if (this.form.resource_id == '') {
				return this.$message.warn('请上传图片！', 1.5);
			}
			if (this.form.title == '') {
				return this.$message.warn('请输入标题！', 1.5);
			}
			if (this.linkIdArr.length == 0) {
				return this.$message.warn('请选择链接！', 1.5);
			}
			this.editIng = true;
			let data = JSON.parse(JSON.stringify(this.form));
			let url = '',
				mg = '';
			if (t.editSwiper.id) {
				url = 'BannerEdit';
				mg = '修改成功';
				data.banner_id = t.editSwiper.id;
			} else {
				mg = '添加成功';
				url = 'BannerAdd';
			}
			t.$post(url, data)
				.then(res => {
					let { code, data, msg } = res;
					this.editIng = false;
					if (code == 0) {
						t.$message.success(mg, 1.5);
						if (t.editSwiper.id == '') {
							t.page.current = 1;
						}
						t.swiperModal = false;
						this.initForm();
						t.getSwiper();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					this.editIng = false;
				});
		},
		cancelSwiper() {
			this.swiperModal = false;
			this.editSwiper.id = '';
			this.initForm();
		},
		initForm() {
			this.form = {
				resource_id: '',
				banner_class: 5,
				page_key: '',
				title: '',
				page_name: '',
				class_id: '',
				class_name: '',
				param_id: '',
				param_name: '',
				is_show: 1,
				sort: 1
			};
			this.linkIdArr = [];
		},
		deleteSwiper(id) {
			let t = this;
			t.deling = true;
			t.$post('bannerDelete', { banner_id: id })
				.then(res => {
					t.deling = false;
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.getSwiper();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.deling = false;
				});
		}
	}
};
</script>

<style lang="less"></style>
